import React, { useEffect, useState } from 'react'
import * as Constants from "../../common/constant";
import { getRequest } from '../../common';
import * as Constant from "../../common/baseurl";
import { useLocation } from 'react-router-dom';

function CottonWaste() {

    const data = useLocation();
    const id = data?.state?.id;
    const [cottonwWasteData, setCottonwWasteData] = useState([]);

    useEffect(() => {
        getCottonwWasteData();
    }, []);

    // getCottonwWasteData
    const getCottonwWasteData = async () => {
        const params = {
            isactive: true
        }
        try {
            const result = await getRequest(Constants.GetCottonWasteData + id, params);
            if (result?.status === Constants.CODE_SUCCESS) {
                const data = result.response;
                setCottonwWasteData(data)
            }
        } catch (error) {
            console.log(error, "error")
        }
    }
    return (
        <div>
            <section className="product-section pt-3 pb-3">
                <div className="container">
                    <div className="row inner-product-list">
                        <div className="col-lg-9 col-md-12">
                            <div className="product-points">
                                <ul>
                                    {cottonwWasteData?.map((item, index) => {
                                        return (
                                            <li key={index}>{item?.waste_title}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {cottonwWasteData?.map((item, index) => {
                        return (
                            <div className="row inner-data pt-3" key={index}>
                                {(item?.cottontabledata?.length != 0 || item?.cottonWasteMasterDetail?.length != 0) && (
                                    <>
                                        <div className="col-lg-6 col-md-12"
                                            data-aos="fade-right"
                                            data-aos-easing="linear"
                                            data-aos-duration="1000">
                                            <div className="inner-info">
                                                <h3>{item?.waste_title}</h3>
                                                <div dangerouslySetInnerHTML={{ __html: item?.waste_description }}></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12"
                                            data-aos="fade-left"
                                            data-aos-easing="linear"
                                            data-aos-duration="1000">
                                            <div className="responsive_table table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            {item?.cottonWasteMasterDetail && item.cottonWasteMasterDetail.length > 0 ? (
                                                                <>
                                                                    {item?.cottonWasteMasterDetail?.some(data => data?.products) && <th>Products</th>}
                                                                    {item?.cottonWasteMasterDetail?.some(data => data?.length) && <th>Length</th>}
                                                                    {item?.cottonWasteMasterDetail?.some(data => data?.micronaire) && <th>Micronaire</th>}
                                                                    {item?.cottonWasteMasterDetail?.some(data => data?.strength) && <th>Strength</th>}
                                                                    {item?.cottonWasteMasterDetail?.some(data => data?.trash) && <th>Trash</th>}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {item?.cottontabledata?.some(data => data?.name) && <th>Name</th>}
                                                                    {item?.cottontabledata?.some(data => data?.value) && <th>Value</th>}
                                                                </>
                                                            )}
                                                        </tr>
                                                        {item?.cottonWasteMasterDetail && item?.cottonWasteMasterDetail?.length > 0 ? (
                                                            item?.cottonWasteMasterDetail?.map((data, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        {data?.products && <td className='gray-bg'>{data?.products}</td>}
                                                                        {data?.length && <td className='light-bg'>{data?.length}</td>}
                                                                        {data?.micronaire && <td className='light-bg'>{data?.micronaire}</td>}
                                                                        {data?.strength && <td className='light-bg'>{data?.strength}</td>}
                                                                        {data?.trash && <td className='light-bg'>{data?.trash}</td>}
                                                                    </tr>
                                                                )
                                                            })
                                                        ) : (
                                                            item?.cottontabledata?.map((data, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        {data?.name && <td className='gray-bg'>{data?.name}</td>}
                                                                        {data?.value && <td className='light-bg'>{data?.value}</td>}
                                                                    </tr>
                                                                )
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {item?.cottontabledata?.length === 0 && item?.cottonWasteMasterDetail?.length === 0 && (
                                    <div className="col-12 col-md-12"
                                        data-aos="fade-right"
                                        data-aos-easing="linear"
                                        data-aos-duration="1000">
                                        <div className="inner-info">
                                            <h3>{item?.waste_title}</h3>
                                            <div dangerouslySetInnerHTML={{ __html: item?.waste_description }}></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </section>
        </div>
    )
}

export default CottonWaste
