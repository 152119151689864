import React, { useEffect, useState } from 'react';
import { getRequest } from '../common';
import * as Constants from '../common/constant';
import * as Constant from '../common/baseurl';
import Slider from 'react-slick';
import { Modal } from 'react-bootstrap';
import './gallery.css'

const Gallery = () => {
  const [headerData, setHeaderData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [galleryData1, setGalleryData1] = useState([]);
  const [galleryData2, setGalleryData2] = useState([]);
  const [show, setShow] = useState(false);
  const [click, setClick] = useState("");

  useEffect(() => {
    getHeaderData();
    getGalleryData();
    getGalleryData1();
    getGalleryData2();
  }, []);

  const getHeaderData = async () => {
    const params = {
      isactive: true,
      header: 'gallerypage',
    };

    try {
      const result = await getRequest(Constants.GetHeaderData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setHeaderData(data[0]);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  //first gallry section api
  const getGalleryData = async () => {
    const params = {
      isactive: true,
    };

    try {
      const result = await getRequest(Constants.GetGalleryData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setGalleryData(data);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  //second gallry section api
  const getGalleryData1 = async () => {
    const params = {
      isactive: true,
    };

    try {
      const result = await getRequest(Constants.GetGalleryData1, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setGalleryData1(data);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  //third gallry section api
  const getGalleryData2 = async () => {
    const params = {
      isactive: true,
    };

    try {
      const result = await getRequest(Constants.GetGalleryData2, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setGalleryData2(data);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  // modal slider setting
  const settingsmodal = {
    className: "center",
    centerMode: false,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
  };

  // settings for slider one
  const settings = {
    className: "center",
    centerMode: false,
    infinite: galleryData.length >= 8 ? true : false,
    slidesToShow: 4,
    speed: 500,
    rows: galleryData.length >= 5 ? 2 : 1,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: galleryData.length >= 8 ? true : false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true

        }
      }
    ]
  };

  //settings for slider two
  const settings1 = {
    className: "center",
    centerMode: false,
    infinite: galleryData1.length >= 8 ? true : false,
    centerPadding: "60px",
    slidesToShow: 4,
    speed: 500,
    rows: galleryData1.length >= 5 ? 2 : 1,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: galleryData1.length >= 8 ? true : false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true

        }
      }
    ]
  };
  //settings for slider three
  const settings2 = {
    className: "center",
    centerMode: false,
    infinite: galleryData2.length >= 8 ? true : false,
    centerPadding: "60px",
    slidesToShow: 4,
    speed: 500,
    rows: galleryData2.length >= 5 ? 2 : 1,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: galleryData2.length >= 8 ? true : false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <main id="main" className="aux-main aux-territory  aux-single aux-page aux-full-container no-sidebar aux-sidebar-style-border aux-user-entry">
        <div className="aux-wrapper">
          <div className="aux-container aux-fold">
            <div id="primary" className="aux-primary">
              <div className="content" role="main">
                <article id="post-2479" className="post-2479 page type-page status-publish hentry">
                  <div className="entry-main">
                    <div className="entry-content">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id={2479}
                        className="elementor elementor-2479"
                      >
                        <div
                          className="elementor-element elementor-element-58143af e-flex e-con-boxed e-con e-parent"
                          data-id="58143af"
                          style={{
                            backgroundImage: `url(${Constant?.IMAGE_URL_API}${headerData?.img_url})`,
                          }}
                          data-element_type="container"
                          data-settings='{"background_background":"classic","shape_divider_bottom":"mountains","content_width":"boxed"}'
                          data-core-v316-plus="true"
                        >
                          <div className="e-con-inner">
                            <div
                              className="elementor-shape elementor-shape-bottom"
                              data-negative="false"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 1000 100"
                                preserveAspectRatio="none"
                              >
                                <path
                                  className="elementor-shape-fill"
                                  opacity="0.33"
                                  d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"
                                />
                                <path
                                  className="elementor-shape-fill"
                                  opacity="0.66"
                                  d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"
                                />
                                <path
                                  className="elementor-shape-fill"
                                  d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"
                                />
                              </svg>{" "}
                            </div>
                            <div
                              className="elementor-element elementor-element-72cac61 elementor-widget elementor-widget-heading"
                              data-id="72cac61"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h1 className="elementor-heading-title elementor-size-default">
                                  {headerData?.title}
                                </h1>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-805a955 elementor-widget elementor-widget-heading"
                              data-id="805a955"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">
                                  <a href="/" role='button'>Home</a> / {headerData?.title}
                                </h2>{" "}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="top-0 elementor-element elementor-element-c98732f e-flex e-con-boxed e-con e-parent"
                          data-id="c98732f"
                          data-element_type="container"
                          data-settings='{"content_width":"boxed"}'
                          data-core-v316-plus="true"
                        >
                          <div className="e-con-inner">
                            <div
                              className="elementor-element elementor-element-4752476 elementor-widget elementor-widget-google_maps"
                              data-id={4752476}
                              data-element_type="widget"
                              data-widget_type="google_maps.default"
                            >

                              {show && <Modal show={show} className='modal-xl' onHide={handleClose}>
                                <Modal.Header closeButton>

                                </Modal.Header>
                                <Modal.Body>
                                  <Slider {...settingsmodal}>
                                    {click === "First" && galleryData.map((data, index) => (
                                      <div key={index}>
                                        <img className='w-full rounded img-width-slider' src={Constant?.IMAGE_URL_API + data?.img_url} />
                                      </div>
                                    ))}
                                    {click === "Second" && galleryData1.map((data, index) => (
                                      <div key={index}>
                                        <img className='w-full rounded img-width-slider ' src={Constant?.IMAGE_URL_API + data?.img_url} />
                                      </div>
                                    ))}
                                    {click === "Third" && galleryData2.map((data, index) => (
                                      <div key={index}>
                                        <img className='w-full rounded img-width-slider' src={Constant?.IMAGE_URL_API + data?.img_url} />
                                      </div>
                                    ))}
                                  </Slider>
                                </Modal.Body>
                              </Modal>}
                              {galleryData?.length > 0 &&
                                <div className="elementor-widget-container gallery-page" data-aos="fade-right"
                                  data-aos-easing="linear"
                                  data-aos-duration="1000">
                                  <div  >
                                    <Slider {...settings}>
                                      {galleryData.map((data, index) => (
                                        <div className='mx-2 image-width' key={index} >
                                          <img className='w-full rounded  img-width-slider ' onClick={() => { setShow(true); setClick("First") }} src={Constant?.IMAGE_URL_API + data?.img_url} />
                                        </div>
                                      ))}
                                    </Slider>
                                  </div>
                                </div>} {galleryData1?.length >= 0 &&
                                  <div className="elementor-widget-container gallery-page" data-aos="fade-left"
                                    data-aos-easing="linear"
                                    data-aos-duration="1000">
                                    <div  >
                                      <Slider {...settings1}>
                                        {galleryData1.map((data, index) => (
                                          <div className='mx-2 image-width' key={index}>
                                            <img className='w-full rounded img-width-slider' onClick={() => { setShow(true); setClick("Second") }} src={Constant?.IMAGE_URL_API + data?.img_url} />
                                          </div>
                                        ))}
                                      </Slider>
                                    </div>
                                  </div>} {galleryData2?.length >= 0 &&
                                    <div className="elementor-widget-container gallery-page " data-aos="fade-right"
                                      data-aos-easing="linear"
                                      data-aos-duration="1000">
                                      <div  >
                                        <Slider {...settings2}>
                                          {galleryData2.map((data, index) => (
                                            <div className='mx-2 image-width' key={index}>
                                              <img className='w-full rounded  img-width-slider' onClick={() => { setShow(true); setClick("Third") }} src={Constant?.IMAGE_URL_API + data?.img_url} />
                                            </div>
                                          ))}
                                        </Slider>
                                      </div>
                                    </div>}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="clear" />
                    </div>{" "}
                    <footer className="entry-meta"></footer>{" "}
                  </div>
                </article>{" "}
                <div className="clear" />
                <div className="clear" />
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  );
};

export default Gallery;
