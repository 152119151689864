import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Constants from "../common/constant";
import { getRequest } from '../common';
import * as Constant from "../common/baseurl";

function Header() {

  const [categoryData, setCategoryData] = useState([]);
  const navigate = useNavigate();
  const [footerData, setFooterData] = useState([]);
  useEffect(() => {
    getCategoryData();
    getFooterData();
  }, []);

  // getCategoryData
  const getCategoryData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetCategoryData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setCategoryData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // getFooterData
  const getFooterData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetFooterData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setFooterData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };

  return (
    <div>
      <header
        className="aux-elementor-header aux-overlay-header"
        id="site-elementor-header"
        itemScope="itemscope"
        itemType="https://schema.org/WPHeader"
        data-sticky-height={80}
      >
        <div className="aux-wrapper">
          <div className="aux-header aux-header-elements-wrapper">
            <div
              data-elementor-type="header"
              data-elementor-id={84}
              className="elementor elementor-84"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-5524fd57 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="5524fd57"
                data-element_type="section"
                data-settings='{"background_background":"classic"}'
              >
                <div className="elementor-background-overlay" />
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="aux-parallax-section elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7b71b6c"
                    data-id="7b71b6c"
                    data-element_type="column"
                    data-settings='{"background_background":"classic"}'
                  >
                    <div className="elementor-widget-wrap elementor-element-populated rmv-bg">
                      <section
                        className="elementor-section elementor-inner-section sticky-menu elementor-element elementor-element-46c6e123 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="46c6e123"
                        data-element_type="section"
                      >
                        <div className="elementor-container elementor-column-gap-default">
                          <div
                            className="aux-parallax-section elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7c320b2a"
                            data-id="7c320b2a"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated ">
                              <div
                                className="elementor-element elementor-element-5b1cd141  elementor-widget__width-auto  elementor-widget elementor-widget-image"
                                data-id="5b1cd141"
                                data-element_type="widget"
                                data-aos="fade-right"
                                data-aos-easing="linear"
                                data-aos-duration="1500"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <a href="/">
                                    <img
                                      width={550}
                                      height={205}
                                      src={Constant.IMAGE_URL_API + footerData?.logo_icon}
                                      className="attachment-full size-full wp-image-96 lazy"
                                      alt="shivogaoverseas_logo"
                                    />{" "}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="aux-parallax-section elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-31e22d3d"
                            data-id="31e22d3d"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated menu-center">
                              <div
                                className="elementor-element elementor-element-3dd2ac90 margin-top-menu  elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-mobile elementor-widget elementor-widget-ekit-nav-menu"
                                data-id="3dd2ac90"
                                data-element_type="widget"
                                data-aos="fade-left"
                                data-aos-easing="linear"
                                data-aos-duration="1500"
                                data-widget_type="ekit-nav-menu.default"
                              >
                                <div className="elementor-widget-container">
                                  <div
                                    className="ekit-wid-con ekit_menu_responsive_tablet"
                                    data-hamburger-icon="icon icon-menu-11"
                                    data-hamburger-icon-type="icon"
                                    data-responsive-breakpoint={1024}
                                  >
                                    {" "}
                                    <button
                                      className="elementskit-menu-hamburger elementskit-menu-toggler"
                                      type="button"
                                      aria-label="hamburger-icon"
                                    >
                                      <i
                                        aria-hidden="true"
                                        className="ekit-menu-icon icon icon-menu-11"
                                      />
                                    </button>
                                    <nav
                                      id="ekit-megamenu-aux-portfolio-creative"
                                      className="elementskit-menu-container elementskit-menu-offcanvas-elements elementskit-navbar-nav-default ekit-nav-menu-one-page-no ekit-nav-dropdown-hover"
                                    >
                                      <ul
                                        id="menu-aux-portfolio-creative"
                                        className="elementskit-navbar-nav elementskit-menu-po-right submenu-click-on-icon"
                                        data-type="horizontal"
                                      >
                                        <li
                                          id="menu-item-88"
                                          className="a1i0s0 menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-86 current_page_item menu-item-88 nav-item elementskit-mobile-builder-content active"
                                          data-vertical-menu="750px"
                                        >
                                          <a
                                            role='button'
                                            href="/"
                                            className="ekit-menu-nav-link active"
                                          >
                                            Home
                                          </a>
                                        </li>
                                        <li
                                          id="menu-item-90"
                                          className="a1i0s0 menu-item menu-item-type-post_type menu-item-object-page menu-item-90 nav-item elementskit-mobile-builder-content"
                                          data-vertical-menu="750px"
                                        >
                                          <a
                                            href="/about-us"
                                            // rel="noreferrer"
                                            className="ekit-menu-nav-link"
                                          >
                                            About us
                                          </a>
                                        </li>
                                        <li
                                          id="menu-item-119"
                                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-119 nav-item elementskit-dropdown-has relative_position elementskit-dropdown-menu-default_width elementskit-mobile-builder-content"
                                          data-vertical-menu="750px"
                                        >
                                          <a
                                            // href="/"
                                            className="ekit-menu-nav-link ekit-menu-dropdown-toggle"
                                          >
                                            Products
                                            <i
                                              aria-hidden="true"
                                              className="icon icon-down-arrow1 elementskit-submenu-indicator"
                                            />
                                          </a>
                                          <ul className="elementskit-dropdown elementskit-submenu-panel">
                                            {categoryData && categoryData?.map((item, index) => (
                                              <li
                                                key={index}
                                                data-vertical-menu="750px"
                                              >
                                                <a
                                                  role='button'
                                                  onClick={() => {
                                                    navigate(`/products`, { state: { id: item.id } })
                                                    window.location.reload();
                                                  }}
                                                  className="dropdown-item"
                                                >
                                                  {item?.category_title}
                                                </a>
                                              </li>
                                            ))}
                                          </ul>
                                        </li>
                                        <li
                                          id="menu-item-1361"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1361 nav-item elementskit-mobile-builder-content"
                                          data-vertical-menu="750px"
                                        >
                                          <a
                                            href="/markets"
                                            className="ekit-menu-nav-link"
                                          >
                                            Markets
                                          </a>
                                        </li>
                                        <li
                                          id="menu-item-2602"
                                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2602 nav-item elementskit-mobile-builder-content"
                                          data-vertical-menu="750px"
                                        >
                                          <a
                                            href="/gallery"
                                            className="ekit-menu-nav-link"
                                          >
                                            Gallery
                                          </a>
                                        </li>
                                        <li
                                          id="menu-item-92"
                                          className="a1i0s0 menu-item menu-item-type-post_type menu-item-object-page menu-item-92 nav-item elementskit-mobile-builder-content"
                                          data-vertical-menu="750px"
                                        >
                                          <a
                                            href="/contact-us"
                                            className="ekit-menu-nav-link"
                                          >
                                            Contact us
                                          </a>
                                        </li>
                                      </ul>
                                    </nav>
                                    <div className="elementskit-menu-overlay elementskit-menu-offcanvas-elements elementskit-menu-toggler ekit-nav-menu--overlay"></div>
                                  </div>
                                </div>
                              </div>
                             

                                <div id='google_translate_element'></div>
                                <i
                                  aria-hidden="true"
                                  className="icon icon-down-arrow1 elementskit-submenu-indicator translater-icon"
                                />
                              
                              <div
                                className="elementor-element elementor-element-4239d360 elementor-hidden-desktop ekit-off-canvas-position-right elementor-widget elementor-widget-elementskit-header-offcanvas"
                                data-id="4239d360"
                                data-element_type="widget"
                                data-widget_type="elementskit-header-offcanvas.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="ekit-wid-con">
                                    <div className="ekit-offcanvas-toggle-wraper">

                                      <a
                                        href="#"
                                        className="ekit_navSidebar-button ekit_offcanvas-sidebar"
                                        aria-label="offcanvas-menu"
                                      >
                                        <i
                                          aria-hidden="true"
                                          className="icon icon-burger-menu"
                                        />{" "}
                                      </a>
                                    </div>
                                    <div
                                      className="ekit-sidebar-group info-group"
                                      data-settings='{"disable_bodyscroll":""}'
                                    >
                                      <div className="ekit-overlay ekit-bg-black" />
                                      <div className="ekit-sidebar-widget">
                                        <div className="ekit_sidebar-widget-container">
                                          <div className="ekit_widget-heading">

                                            <a
                                              href="#"
                                              className="ekit_close-side-widget"
                                              aria-label="close-icon"
                                            >
                                              <i
                                                aria-hidden="true"
                                                className="icon icon-cross"
                                              />
                                            </a>
                                          </div>
                                          <div className="ekit_sidebar-textwidget">
                                            <div
                                              className="widgetarea_warper widgetarea_warper_editable"
                                              data-elementskit-widgetarea-key="e1f5a59"
                                              data-elementskit-widgetarea-index={
                                                99
                                              }
                                            >
                                              <div
                                                className="widgetarea_warper_edit"
                                                data-elementskit-widgetarea-key="e1f5a59"
                                                data-elementskit-widgetarea-index={
                                                  99
                                                }
                                              >
                                                <i
                                                  className="eicon-edit"
                                                  aria-hidden="true"
                                                />
                                                <span>Edit Content</span>
                                              </div>
                                              <div className="elementor-widget-container">
                                                <div
                                                  data-elementor-type="wp-post"
                                                  data-elementor-id={294}
                                                  className="elementor elementor-294"
                                                >
                                                  <div
                                                    className="elementor-element elementor-element-90677a2 e-flex e-con-boxed e-con e-parent"
                                                    data-id="90677a2"
                                                    data-element_type="container"
                                                    data-settings='{"content_width":"boxed"}'
                                                    data-core-v316-plus="true"
                                                  >
                                                    <div className="e-con-inner">
                                                      <div
                                                        className="elementor-element elementor-element-6b7b5dc elementor-widget elementor-widget-aux_logo"
                                                        data-id="6b7b5dc"
                                                        data-element_type="widget"
                                                        data-widget_type="aux_logo.default"
                                                      >
                                                        <div className="elementor-widget-container">
                                                          <div className="aux-widget-logo">
                                                            <a
                                                              className="aux-logo-anchor aux-has-logo"
                                                              title="Shivoga Overseas"
                                                              href="/"
                                                            >
                                                              <img
                                                                width={550}
                                                                height={205}
                                                                src={Constant.IMAGE_URL_API + footerData?.logo_icon}
                                                                className="aux-attachment aux-featured-image attachment-550x205 aux-attachment-id-96  lazy"
                                                                alt="shivogaoverseas_logo"
                                                                data-ratio="2.68"
                                                                data-original-w={
                                                                  550
                                                                }
                                                              />
                                                            </a>
                                                            <section className="aux-logo-text">
                                                              <h3 className="site-title">
                                                                <a
                                                                  href="/"
                                                                  title="Shivoga Overseas"
                                                                >
                                                                  Shivoga Overseas
                                                                </a>
                                                              </h3>
                                                              <p className="site-description">
                                                                Domestic &amp;
                                                                International
                                                                Agent Raw Cotton,
                                                                Cotton Yarn &amp;
                                                                Cotton Waste
                                                              </p>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="elementor-element elementor-element-efa476c e-flex e-con-boxed e-con e-parent"
                                                    data-id="efa476c"
                                                    data-element_type="container"
                                                    data-settings='{"content_width":"boxed"}'
                                                    data-core-v316-plus="true"
                                                  >
                                                    <div className="e-con-inner">
                                                      <div
                                                        className="elementor-element elementor-element-73534dd elementor-widget elementor-widget-aux_menu_box"
                                                        data-id="73534dd"
                                                        data-element_type="widget"
                                                        data-widget_type="aux_menu_box.default"
                                                      >
                                                        <div className="elementor-widget-container">
                                                          <div className="aux-elementor-header-menu aux-nav-menu-element aux-nav-menu-element-73534dd">
                                                            <div
                                                              className="aux-burger-box"
                                                              data-target-panel="toggle-bar"
                                                              data-target-content=".elementor-element-73534dd .aux-master-menu"
                                                            >
                                                              <div className="aux-burger aux-lite-small">
                                                                <span className="mid-line" />
                                                              </div>
                                                            </div>
                                                            <nav
                                                              id="master-menu-elementor-73534dd"
                                                              className="menu-aux-portfolio-creative-container"
                                                            >
                                                              <ul
                                                                id="menu-aux-portfolio-creative-1"
                                                                className="aux-master-menu aux-no-js aux-skin-classic aux-with-indicator aux-vertical"
                                                                data-type="vertical"
                                                                data-switch-type="toggle"
                                                                data-switch-parent=".elementor-element-73534dd .aux-toggle-menu-bar"
                                                                data-switch-width={
                                                                  1
                                                                }
                                                              >
                                                                <li className="a1i0s0 menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-86 current_page_item menu-item-88 aux-menu-depth-0 aux-menu-root-1 aux-menu-item">
                                                                  <a
                                                                    role='button'
                                                                    href="/"
                                                                    className="aux-item-content"
                                                                  >
                                                                    <span className="aux-menu-label">
                                                                      Home
                                                                    </span>
                                                                  </a>
                                                                </li>
                                                                <li className="a1i0s0 menu-item menu-item-type-post_type menu-item-object-page menu-item-90 aux-menu-depth-0 aux-menu-root-2 aux-menu-item">
                                                                  <a
                                                                    role='button'
                                                                    href="/about-us"
                                                                    className="aux-item-content"
                                                                  >
                                                                    <span className="aux-menu-label">
                                                                      About us
                                                                    </span>
                                                                  </a>
                                                                </li>
                                                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-119 aux-menu-depth-0 aux-menu-root-3 aux-menu-item">
                                                                  <a
                                                                    // href="/products"
                                                                    className="aux-item-content"
                                                                  >
                                                                    <span className="aux-menu-label">
                                                                      Products
                                                                    </span>
                                                                  </a>
                                                                  <ul className="sub-menu aux-submenu">
                                                                    {categoryData.map((item, index) => {
                                                                      return (
                                                                        <li key={index} className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1007 aux-menu-depth-1 aux-menu-item">
                                                                          <a
                                                                            onClick={() => {
                                                                              navigate(`/products`, { state: { id: item.id } })
                                                                              window.location.reload();
                                                                            }}
                                                                            className="aux-item-content"
                                                                          >
                                                                            <span className="aux-menu-label">
                                                                              {item?.category_title}
                                                                            </span>
                                                                          </a>
                                                                        </li>
                                                                      )
                                                                    })}                                                                </ul>
                                                                </li>
                                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1361 aux-menu-depth-0 aux-menu-root-4 aux-menu-item">
                                                                  <a
                                                                    href="/markets"
                                                                    className="aux-item-content"
                                                                  >
                                                                    Markets
                                                                  </a>
                                                                </li>
                                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2602 aux-menu-depth-0 aux-menu-root-5 aux-menu-item">
                                                                  <a
                                                                    href="/gallery"
                                                                    className="aux-item-content"
                                                                  >
                                                                    Gallery
                                                                  </a>
                                                                </li>
                                                                <li className="a1i0s0 menu-item menu-item-type-post_type menu-item-object-page menu-item-92 aux-menu-depth-0 aux-menu-root-6 aux-menu-item">
                                                                  <a
                                                                    href="/contact-us"
                                                                    className="aux-item-content"
                                                                  >
                                                                    <span className="aux-menu-label">
                                                                      Contact us
                                                                    </span>
                                                                  </a>
                                                                </li>
                                                              </ul>
                                                            </nav>
                                                            <div className="aux-toggle-menu-bar"></div>
                                                          </div>
                                                          <style
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t@media only screen and (min-width: 2px) {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-element-73534dd .aux-burger-box {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: none\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </header></div>
  )
}

export default Header
