import React, { useEffect, useState } from 'react'
import Validation from '../common/validation';
import * as Constants from "../common/constant";
import { getRequest, postRequest } from '../common';
import { toast } from 'react-toastify';
import * as Constant from "../common/baseurl";

function ContactUs() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [headerData, setHeaderData] = useState([]);
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const charRegex = /^[a-zA-Z]+$/;
  const number = new RegExp("[0-9]{10}");
  const [footerData, setFooterData] = useState([]);
  const [socialIconData, setSocialIconData] = useState([]);
  const [contactCottonData, setContactCottonData] = useState([]);

  useEffect(() => {
    getFooterData();
    getSocialMediaData();
    getContactCottonData();
    getHeaderData();
  }, []);

  // getHeaderData
  const getHeaderData = async () => {
    const params = {
      isactive: true,
      header: "contactpage"
    }
    try {
      const result = await getRequest(Constants.GetHeaderData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setHeaderData(data[0])
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // getContactCottonData
  const getContactCottonData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetContactCottonData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setContactCottonData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // getSocialMediaData
  const getSocialMediaData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetSocialMediaData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setSocialIconData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // onSubmit
  const onSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = Validation({ email, contact, subject, name });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitLoading(true);
      const data = {
        user_name: name,
        emailid: email,
        phoneno: contact,
        subject: subject,
        message: message
      }
      try {
        const result = await postRequest(Constants.saveContactData, data);
        if (result?.status === Constants.CODE_SUCCESS) {
          toast.success(result?.message);
          setSubmitLoading(false);
          clearData();
        } else {
          setSubmitLoading(false);
          toast.warning(result?.message);
        }
      } catch (error) {
        console.log("error", error)
        setErrors({});
      }
    }
  }
  // getFooterData
  const getFooterData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetFooterData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setFooterData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // clearData
  const clearData = () => {
    setName("");
    setEmail("");
    setErrors({});
    setSubject("");
    setContact("");
    setMessage("");
  }
  // handleNameChange
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    if (charRegex.test(inputValue) || inputValue === '') {
      setName(inputValue);
    }
  }

  return (
    <>
      <main
        id="main"
        className="aux-main aux-territory  aux-single aux-page aux-full-container no-sidebar aux-sidebar-style-border aux-user-entry"
      >
        <div className="aux-wrapper">
          <div className="aux-container aux-fold">
            <div id="primary" className="aux-primary">
              <div className="content" role="main">
                <article
                  id="post-81"
                  className="post-81 page type-page status-publish hentry"
                >
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id={81}
                    className="elementor elementor-81"
                  >
                    <div
                      className="elementor-element elementor-element-1b2f89b e-flex e-con-boxed e-con e-parent"
                      data-id="1b2f89b"
                      style={{
                        backgroundImage: `url(${Constant?.IMAGE_URL_API}${headerData?.img_url})`,
                      }}
                      data-element_type="container"
                      data-settings='{"background_background":"classic","shape_divider_bottom":"mountains","content_width":"boxed"}'
                      data-core-v316-plus="true"
                    >
                      <div className="e-con-inner">
                        <div
                          className="elementor-shape elementor-shape-bottom"
                          data-negative="false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1000 100"
                            preserveAspectRatio="none"
                          >
                            <path
                              className="elementor-shape-fill"
                              opacity="0.33"
                              d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"
                            />
                            <path
                              className="elementor-shape-fill"
                              opacity="0.66"
                              d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"
                            />
                            <path
                              className="elementor-shape-fill"
                              d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"
                            />
                          </svg>{" "}
                        </div>
                        <div
                          className="elementor-element elementor-element-53c298a elementor-widget elementor-widget-heading"
                          data-id="53c298a"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <h1 className="elementor-heading-title elementor-size-default">
                              {headerData?.title}
                            </h1>{" "}
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-f97e620 elementor-widget elementor-widget-heading"
                          data-id="f97e620"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container" >
                            <h2 className="elementor-heading-title elementor-size-default">
                              <a href="/" role='button'>Home</a> / {headerData?.title}
                            </h2>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-20da3f6 e-flex e-con-boxed e-con e-parent"
                      data-id="20da3f6"
                      data-element_type="container"
                      data-settings='{"content_width":"boxed"}'
                      data-core-v316-plus="true"
                    >
                      <div className="e-con-inner">
                        <div
                          className="elementor-element elementor-element-d4a8373 elementor-widget elementor-widget-spacer"
                          data-id="d4a8373"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-4a4fa5b e-flex e-con-boxed e-con e-parent"
                      data-id="4a4fa5b"
                      data-element_type="container"
                      data-settings='{"content_width":"boxed"}'
                      data-core-v316-plus="true"
                    >
                      <div className="e-con-inner">
                        <div
                          className="elementor-element elementor-element-2dbd23e e-con-full e-flex e-con e-child"
                          data-id="2dbd23e"
                          data-element_type="container"
                          data-settings='{"content_width":"full"}'
                        >
                          <div
                            className="elementor-element elementor-element-66a6a89 elementor-widget elementor-widget-heading"
                            data-id="66a6a89"
                            data-element_type="widget"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1000"
                            data-widget_type="heading.default"
                          >
                            <div className="elementor-widget-container">
                              <h2 className="elementor-heading-title elementor-size-default">
                                Contact Us
                              </h2>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-6ac4d2f elementor-widget elementor-widget-heading"
                            data-id="6ac4d2f"
                            data-element_type="widget"
                            data-aos="fade-left"
                            data-aos-easing="linear"
                            data-aos-duration="1000"
                            data-widget_type="heading.default"
                          >
                            <div className="elementor-widget-container">
                              <h2 className="elementor-heading-title elementor-size-default">
                                {footerData?.touch_title}
                              </h2>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-c396885 elementor-widget elementor-widget-text-editor"
                            data-id="c396885"
                            data-element_type="widget"
                            data-aos="fade-left"
                            data-aos-easing="linear"
                            data-aos-duration="1000"
                            data-widget_type="text-editor.default"
                          >
                            <div className="elementor-widget-container">
                              <p>
                                {footerData?.touch_description}
                              </p>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-911b2b9 e-con-full e-flex e-con e-child"
                            data-id="911b2b9"
                            data-element_type="container"
                            data-settings='{"content_width":"full"}'
                          >
                            <div
                              className="elementor-element elementor-element-175148c elementor-view-stacked elementor-position-left elementor-widget__width-initial elementor-shape-circle elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                              data-id="175148c"
                              data-element_type="widget"
                              data-aos="fade-down"
                              data-aos-easing="linear"
                              data-aos-duration="1000"
                              data-widget_type="icon-box.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-icon-box-wrapper">
                                  <div className="elementor-icon-box-icon">
                                    <span className="elementor-icon elementor-animation-">
                                      <i
                                        aria-hidden="true"
                                        className="icon icon-placeholder"
                                      />{" "}
                                    </span>
                                  </div>
                                  <div className="elementor-icon-box-content">
                                    <h3 className="elementor-icon-box-title">
                                      <span>Address </span>
                                    </h3>
                                    <p className="elementor-icon-box-description">
                                      {footerData?.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-89413af e-con-full e-flex e-con e-child"
                            data-id="89413af"
                            data-element_type="container"
                            data-settings='{"content_width":"full"}'
                          >
                            <div
                              className="elementor-element elementor-element-ebf9cba elementor-view-stacked elementor-position-left elementor-widget__width-inherit elementor-shape-circle elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                              data-id="ebf9cba"
                              data-element_type="widget"
                              data-aos="fade-left"
                              data-aos-easing="linear"
                              data-aos-duration="1000"
                              data-widget_type="icon-box.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-icon-box-wrapper">
                                  <div className="elementor-icon-box-icon">
                                    <span className="elementor-icon elementor-animation-">
                                      <i
                                        aria-hidden="true"
                                        className="icon icon-phone-call"
                                      />{" "}
                                    </span>
                                  </div>
                                  <div className="elementor-icon-box-content">
                                    <h3 className="elementor-icon-box-title">
                                      <span>Phone </span>
                                    </h3>
                                    <p className="elementor-icon-box-description">
                                      {footerData?.phoneno1}
                                      <br />
                                      {footerData?.phoneno2}

                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-14cb13d e-con-full e-flex e-con e-child"
                            data-id="14cb13d"
                            data-element_type="container"
                            data-settings='{"content_width":"full"}'
                          >
                            <div
                              className="elementor-element elementor-element-95d2ae8 elementor-view-stacked elementor-position-left elementor-widget__width-inherit elementor-shape-circle elementor-mobile-position-top elementor-vertical-align-top  elementor-widget elementor-widget-icon-box"
                              data-id="95d2ae8"
                              data-element_type="widget"
                              data-aos="fade-left"
                              data-aos-easing="linear"
                              data-aos-duration="1000"
                              data-widget_type="icon-box.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-icon-box-wrapper">
                                  <div className="elementor-icon-box-icon">
                                    <span className="elementor-icon elementor-animation-">
                                      <i
                                        aria-hidden="true"
                                        className="auxicon auxicon-mail"
                                      />{" "}
                                    </span>
                                  </div>
                                  <div className="elementor-icon-box-content">
                                    <h3 className="elementor-icon-box-title">
                                      <span>Email </span>
                                    </h3>
                                    <p className="elementor-icon-box-description">
                                      <a
                                        style={{ color: "#3C3C3C" }}
                                        href={"mailto:" + footerData?.emailid2}
                                      >
                                        {footerData?.emailid1}
                                      </a>
                                      <br />
                                      <a
                                        style={{ color: "#3C3C3C" }}
                                        href={"mailto:" + footerData?.emailid2}
                                      >
                                        {footerData?.emailid2}
                                      </a>{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {socialIconData.length > 0 && (
                            <div
                              className="elementor-element elementor-element-14c4c3a e-con-full e-flex e-con e-child"
                              data-id="14c4c3a"
                              data-element_type="container"
                              data-settings='{"content_width":"full"}'
                            >
                              <div
                                className="elementor-element elementor-element-0a4591e elementor-widget elementor-widget-elementskit-social-media"
                                data-id="0a4591e"
                                data-element_type="widget"
                                data-aos="fade-up"
                                data-aos-easing="linear"
                                data-aos-duration="1000"
                                data-widget_type="elementskit-social-media.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="ekit-wid-con">
                                    {" "}
                                    <ul className="ekit_social_media">
                                      {socialIconData?.map((item, index) => {
                                        return (
                                          <li key={index} className="elementor-repeater-item-da8f4de">
                                            <a
                                              href={item?.social_link}
                                              target="_blank"
                                              aria-label="Facebook"
                                              className="social-icon-image-center"
                                            >
                                              <img src={Constant.IMAGE_URL_API + item?.social_icon} className='social-icon-image' alt="" />
                                            </a>
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>)}
                        </div>
                        <div
                          className="elementor-element elementor-element-11a34ee e-con-full e-flex e-con e-child"
                          data-id="11a34ee"
                          data-element_type="container"
                          data-settings='{"content_width":"full","background_background":"classic"}'
                        >
                          <div
                            className="elementor-element elementor-element-30d1dfd elementor-widget elementor-widget-heading"
                            data-id="30d1dfd"
                            data-element_type="widget"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1000"
                            data-widget_type="heading.default"
                          >
                            <div className="elementor-widget-container">
                              <h2 className="elementor-heading-title elementor-size-default">
                                Contact Form
                              </h2>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-94d1642 elementor-widget elementor-widget-text-editor"
                            data-id="94d1642"
                            data-element_type="widget"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1000"
                            data-widget_type="text-editor.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="section-title title-style-center_text">
                                <div className="title-desc">
                                  <p>
                                    Feel free to contact us through Instagram or
                                    Facebook if you prefer.
                                  </p>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-910f045 elementor-widget elementor-widget-aux_contact_form"
                            data-id="910f045"
                            data-element_type="widget"
                            data-aos="fade-left"
                            data-aos-easing="linear"
                            data-aos-duration="1000"
                            data-widget_type="aux_contact_form.default"
                          >
                            <div className="elementor-widget-container">
                              <section className="widget-container aux-widget-contact-form aux-parent-au78cb4d19">
                                {" "}
                                <div className="aux-col-wrapper aux-no-gutter">
                                  {/* @TODO - The output for element here */}
                                  <div
                                    className="wpcf7 no-js"
                                    id="wpcf7-f748-p81-o1"
                                    lang="en-US"
                                    dir="ltr"
                                  >
                                    <div className="screen-reader-response">
                                      <p
                                        role="status"
                                        aria-live="polite"
                                        aria-atomic="true"
                                      />{" "}
                                      <ul />
                                    </div>
                                    <form onSubmit={onSubmit}>
                                      <div className="contact-form">

                                        <div className="contact-item">
                                          <label htmlFor="">
                                            Your Name <span className='text-danger'>*</span>
                                          </label>
                                          <input type="text" placeholder='Your Name'
                                            name="name"
                                            id="name"
                                            onChange={handleNameChange}
                                            value={name}
                                            className='mb-0' />
                                          {errors.name && !name ?
                                            <span className="error">
                                              {errors.name}
                                            </span> : null
                                          }
                                        </div>
                                        <div className="contact-item">
                                          <label htmlFor="" >
                                            Your Email <span className='text-danger'>*</span>
                                          </label>
                                          <input type="text" placeholder='Your Email'
                                            name="email"
                                            id="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email} />
                                          {errors?.email && !pattern?.test(email) ? <span className="error">{errors?.email}</span> : null}
                                        </div>
                                        <div className="contact-item">

                                          <label htmlFor="" className='margin-coustom'>
                                            Your Phone <span className='text-danger'>*</span>
                                          </label>
                                          <input type="text" placeholder='Your Number'
                                            name="contact"
                                            id="contact"
                                            onChange={(e) => setContact(e.target.value)}
                                            value={contact} />
                                          {errors?.contact && !number?.test(contact) ? <span className="error">{errors?.contact}</span> : null}
                                        </div>
                                        <div className="contact-item">

                                          <label htmlFor="" className='margin-coustom'>
                                            Subject <span className='text-danger'>*</span>
                                          </label>
                                          <input type="text" placeholder="Subject"
                                            name='subject'
                                            id='subject'
                                            onChange={(e) => setSubject(e.target.value)}
                                            value={subject} />
                                          {errors.subject && !subject ?
                                            <span className="error">
                                              {errors.subject}
                                            </span> : null
                                          }
                                        </div>
                                      </div>
                                      <label htmlFor="" className='margin-coustom'>Your Message (Optional)</label>
                                      <textarea type="text" placeholder="Your Message"
                                        name='message'
                                        id='message'
                                        onChange={(e) => setMessage(e.target.value)}
                                        value={message} />
                                      <button
                                        disabled={submitLoading}
                                        type="primary"
                                        htmltype="submit"
                                        className="wpcf7-form-control wpcf7-submit has-spinner submit-button"
                                      >
                                        SUBMIT
                                      </button>
                                    </form>
                                  </div>
                                </div>
                                {/* aux-col-wrapper */}
                              </section>
                              {/* widget-container */}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-2ab89f7 e-flex e-con-boxed e-con e-parent"
                      data-id="2ab89f7"
                      data-element_type="container"
                      data-settings='{"content_width":"boxed"}'
                      data-core-v316-plus="true"
                    >
                      <div className="e-con-inner">
                        <div
                          className="elementor-element elementor-element-20ca279 elementor-widget elementor-widget-spacer"
                          data-id="20ca279"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {contactCottonData.length > 0 && (
                      <section
                        className="elementor-section elementor-top-section elementor-element elementor-element-4790376e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="4790376e"
                        data-element_type="section"
                      >
                        <div className="elementor-container elementor-column-gap-default">
                          <div
                            className="aux-parallax-section elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-22f63a73"
                            data-id="22f63a73"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-3b7cf8f6 elementor-absolute elementor-widget__width-inherit elementor-widget elementor-widget-heading"
                                data-id="3b7cf8f6"
                                data-element_type="widget"
                                data-settings='{"_position":"absolute"}'
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <p className="elementor-heading-title elementor-size-default">
                                    Contact
                                  </p>{" "}
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-d8e769c elementor-widget elementor-widget-elementskit-heading"
                                data-id="d8e769c"
                                data-element_type="widget"
                                data-widget_type="elementskit-heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="ekit-wid-con">
                                    <div className="ekit-heading elementskit-section-title-wraper text_center   ekit_heading_tablet-   ekit_heading_mobile-">
                                      <h2 className="ekit-heading--title elementskit-section-title ">
                                        Reach To Us
                                      </h2>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                              <section
                                className="elementor-section elementor-inner-section elementor-element elementor-element-6b37bd4f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="6b37bd4f"
                                data-element_type="section"
                              >
                                <div className="elementor-container elementor-column-gap-default">
                                  {contactCottonData.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="aux-parallax-section elementor-column elementor-col-33 contact-box elementor-inner-column elementor-element elementor-element-54da78ec"
                                        data-id="54da78ec"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-212efcfe ekit-equal-height-disable  elementor-widget elementor-widget-elementskit-icon-box"
                                            data-id="212efcfe"
                                            data-element_type="widget"
                                            data-aos="fade-up"
                                            data-aos-easing="linear"
                                            data-aos-duration="1000"
                                            data-widget_type="elementskit-icon-box.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="ekit-wid-con">
                                                {" "}
                                                {/* link opening */}
                                                {/* end link opening */}
                                                <div className="elementskit-infobox cotton-yarn-coustom text-center text-left icon-top-align ">
                                                  <div className="elementskit-box-header ">
                                                    <div className="elementskit-info-box-icon">
                                                      <i
                                                        aria-hidden="true"
                                                        className="elementkit-infobox-icon icon icon-phone-call2"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="box-body">
                                                    <h3 className="elementskit-info-box-title">
                                                      {item?.title}
                                                    </h3>
                                                    <p>
                                                      {item.user_name1 &&
                                                        (
                                                          <>
                                                            {item.user_name1} :
                                                            <a
                                                              style={{ color: "#3C3C3C" }}
                                                            >
                                                              {item?.phoneno1}
                                                            </a>{" "}
                                                            <br />
                                                          </>)}
                                                      {item.user_name2 &&
                                                        (
                                                          <>
                                                            {item.user_name2} :
                                                            <a
                                                              style={{ color: "#3C3C3C" }} 
                                                            >
                                                              {item?.phoneno2}
                                                            </a>{" "}
                                                            <br />
                                                          </>)}
                                                      {item.user_name3 &&
                                                        (
                                                          <>
                                                            {item.user_name3} :
                                                            <a
                                                              style={{ color: "#3C3C3C" }}
                                                            >
                                                              {item?.phoneno3}
                                                            </a>{" "}
                                                            <br />
                                                          </>)}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </section>)}
                    <div
                      className="elementor-element elementor-element-c98732f e-flex e-con-boxed e-con e-parent"
                      data-id="c98732f"
                      data-element_type="container"
                      data-settings='{"content_width":"boxed"}'
                      data-core-v316-plus="true"
                    >
                      <div className="e-con-inner">
                        <div
                          className="elementor-element elementor-element-4752476 elementor-widget elementor-widget-google_maps"
                          data-id={4752476}
                          data-element_type="widget"
                          data-aos="fade-down"
                          data-aos-easing="linear"
                          data-aos-duration="1000"
                          data-widget_type="google_maps.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-custom-embed">
                              <iframe
                                loading="lazy"
                                src={footerData?.map_address}
                                // title="A-719, The One World, 150ft Ring Road, Rajkot"
                                // aria-label="A-719, The One World, 150ft Ring Road, Rajkot."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>{" "}
                {/* end article */}
                <div className="clear" />
                {/* You can start editing here. */}
              </div>
              {/* end content */}
            </div>
            {/* end primary */}
          </div>
          {/* end container */}
        </div>
        {/* end wrapper */}
      </main>
      {/* end main */}
    </>
  )
}
export default ContactUs;