import React, { useEffect, useState } from 'react'
import * as Constants from "../common/constant";
import * as Constant from "../common/baseurl";
import { getRequest } from '../common';

function Aboutus() {

  const [aboutYarnData, setAboutYarnData] = useState([]);
  const [coreData, setCoreData] = useState([]);
  const [messionData, setMessionData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [headerData, setHeaderData] = useState([]);

  useEffect(() => {
    getAboutYarnData();
    getVisionData();
    getCoreValueData();
    GetServiceData();
    getHeaderData();
  }, []);

  // getHeaderData
  const getHeaderData = async () => {
    const params = {
      isactive: true,
      header: "aboutpage"
    }
    try {
      const result = await getRequest(Constants.GetHeaderData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result?.data;
        setHeaderData(data[0])
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // GetServiceData
  const GetServiceData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetServiceData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result.data;
        setServiceData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // getAboutYarnData
  const getAboutYarnData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetAboutYarn, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result.data;
        setAboutYarnData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // getVisionData
  const getVisionData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetVisionData, params);
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result.data;
        setMessionData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };
  // getCoreValueData
  const getCoreValueData = async () => {
    const params = {
      isactive: true
    }
    try {
      const result = await getRequest(Constants.GetCoreValue + "?isactive=true");
      if (result?.status === Constants.CODE_SUCCESS) {
        const data = result;
        setCoreData(data)
      }
    } catch (error) {
      console.log(error, "error")
    }
  };

  return (
    <div>
      <main
        id="main"
        className="aux-main aux-territory  aux-single aux-page aux-full-container no-sidebar aux-sidebar-style-border aux-user-entry"
      >
        <div className="aux-wrapper">
          <div className="aux-container aux-fold">
            <div id="primary" className="aux-primary">
              <div className="content" role="main">
                <article
                  id="post-82"
                  className="post-82 page type-page status-publish hentry"
                >
                  <div
                    data-elementor-type="wp-page"
                    data-elementor-id={82}
                    className="elementor elementor-82"
                  >
                    <div
                      className="elementor-element elementor-element-1275d15 e-flex e-con-boxed e-con e-parent"
                      data-id="1275d15"
                      style={{
                        backgroundImage: `url(${Constant?.IMAGE_URL_API}${headerData?.img_url})`,
                      }}
                      data-element_type="container"
                      data-settings='{"background_background":"classic","shape_divider_bottom":"mountains","content_width":"boxed"}'
                      data-core-v316-plus="true"
                    >
                      <div className="e-con-inner">
                        <div
                          className="elementor-shape elementor-shape-bottom"
                          data-negative="false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1000 100"
                            preserveAspectRatio="none"
                          >
                            <path
                              className="elementor-shape-fill"
                              opacity="0.33"
                              d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"
                            />
                            <path
                              className="elementor-shape-fill"
                              opacity="0.66"
                              d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"
                            />
                            <path
                              className="elementor-shape-fill"
                              d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"
                            />
                          </svg>{" "}
                        </div>
                        <div
                          className="elementor-element elementor-element-fd90d2a elementor-widget elementor-widget-heading"
                          data-id="fd90d2a"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <h1 className="elementor-heading-title elementor-size-default">
                              {headerData?.title}
                            </h1>{" "}
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-dacda66 elementor-widget elementor-widget-heading"
                          data-id="dacda66"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default">
                              <a href="/" role='button'>Home</a> /  {headerData?.title}
                            </h2>{" "}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="elementor-element elementor-element-aec4e1a e-flex e-con-boxed e-con e-parent"
                      data-id="aec4e1a"
                      data-element_type="container"
                      data-settings='{"content_width":"boxed"}'
                      data-core-v316-plus="true"
                    >
                      <div className="e-con-inner">
                        <div
                          className="elementor-element elementor-element-d39cf59 elementor-widget elementor-widget-spacer"
                          data-id="d39cf59"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {aboutYarnData && (
                      <div
                        className="elementor-element elementor-element-44525ec e-flex e-con-boxed e-con e-parent"
                        data-id="44525ec"
                        data-element_type="container"
                        data-settings='{"content_width":"boxed"}'
                        data-core-v316-plus="true"
                      >
                        <div className="e-con-inner">
                          <div
                            className="elementor-element elementor-element-b38fda5 e-con-full e-flex e-con e-child"
                            data-id="b38fda5"
                            data-element_type="container"
                            data-settings='{"content_width":"full"}'
                          >
                            <div
                              className="elementor-element elementor-element-c3f61ca elementor-widget elementor-widget-heading"
                              data-id="c3f61ca"
                              data-element_type="widget"
                              data-aos="fade-down"
                              data-aos-easing="linear"
                              data-aos-duration="1000"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">
                                  About
                                </h2>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-efaf613 elementor-widget elementor-widget-heading"
                              data-id="efaf613"
                              data-element_type="widget"
                              data-aos="fade-right"
                              data-aos-easing="linear"
                              data-aos-duration="1000"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">
                                  {aboutYarnData?.title}
                                </h2>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-8d4bd01 elementor-widget elementor-widget-text-editor"
                              data-id="8d4bd01"
                              data-element_type="widget"
                              data-aos="fade-right"
                              data-aos-easing="linear"
                              data-aos-duration="1000"
                              data-widget_type="text-editor.default"
                            >
                              <div className="elementor-widget-container" dangerouslySetInnerHTML={{ __html: aboutYarnData?.description }}>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-93a331e e-con-full e-flex e-con e-child"
                            data-id="93a331e"
                            data-element_type="container"
                            data-settings='{"content_width":"full"}'
                          >
                            <div
                              className="elementor-element elementor-element-9c0a94c elementor-widget elementor-widget-image"
                              data-id="9c0a94c"
                              data-element_type="widget"
                              data-aos="fade-left"
                              data-aos-easing="linear"
                              data-aos-duration="1000"
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <img
                                  decoding="async"
                                  width={680}
                                  height={550}
                                  src={Constant.IMAGE_URL_API + aboutYarnData?.img_url}
                                  className="attachment-full size-full wp-image-1063 lazy"
                                  alt=""
                                />{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>)}
                    <div
                      className="elementor-element elementor-element-8ec021d e-flex e-con-boxed e-con e-parent"
                      data-id="8ec021d"
                      data-element_type="container"
                      data-settings='{"content_width":"boxed"}'
                      data-core-v316-plus="true"
                    >
                      <div className="e-con-inner">
                        <div
                          className="elementor-element elementor-element-b0c5fcc elementor-widget elementor-widget-spacer"
                          data-id="b0c5fcc"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {messionData?.length > 0 && (<div
                      className="elementor-element elementor-element-7682482 e-flex e-con-boxed e-con e-parent"
                      data-id={7682482}
                      data-element_type="container"
                      data-settings='{"background_background":"classic","content_width":"boxed"}'
                      data-core-v316-plus="true"
                    >
                      <div className="e-con-inners pt-5 text-center pb-5">
                        <div
                          className="elementor-element elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
                          data-element_type="widget"
                          data-aos="fade-up"
                          data-aos-easing="linear"
                          data-aos-duration="1000"
                          data-widget_type="text-editor.default"
                        >
                          <div className='container mt-5 w-75 mission-box-main'>
                            <div className='text-center mb-5'>
                              <h2 className='text-light mission-title'>Our Mission and Vision</h2>
                            </div>
                            <div className='row justify-content-center'>
                              {messionData?.map((item, index) => {
                                return (
                                  <div className='col-lg-4' key={index}>
                                    <div className='our-mission-box text-center'>
                                      <h4 className='text-light'>{item?.title}</h4>
                                      <div className='icon-box'><img src={Constant?.IMAGE_URL_API + item?.img_url}></img></div>
                                      <p className='text-light'>{item?.description}</p>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>)}
                    {coreData?.data?.length > 0 && (<div
                      className="elementor-element elementor-element-01ae5c6 e-con-full e-flex e-con e-parent"
                      data-id="01ae5c6"
                      data-element_type="container"
                      data-settings='{"content_width":"full"}'
                      data-core-v316-plus="true"
                    >
                      <div
                        className="elementor-element elementor-element-5fc7a9f e-con-full e-flex e-con e-child"
                        data-id="5fc7a9f"
                        data-element_type="container"
                        data-settings='{"content_width":"full"}'
                      >
                        <div
                          className="elementor-element elementor-element-e4f5c97 elementor-widget elementor-widget-image"
                          data-id="e4f5c97"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div className="elementor-widget-container">
                            <img
                              decoding="async"
                              width={1024}
                              height={700}
                              src={Constant?.IMAGE_URL_API + coreData?.aboutImg?.img_url}
                              className="attachment-full size-full wp-image-1205 lazy"
                              alt="Shivoga-Overseas-Vision-Mission"
                            />{" "}
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-96b1019 e-con-full e-flex e-con e-child"
                        data-id="96b1019"
                        data-element_type="container"
                        data-settings='{"content_width":"full","background_background":"classic"}'
                      >
                        <div
                          className="elementor-element elementor-element-faf79df elementor-widget elementor-widget-heading"
                          data-id="faf79df"
                          data-element_type="widget"
                          data-aos="fade-right"
                          data-aos-easing="linear"
                          data-aos-duration="1000"
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default">
                              Core Values
                            </h2>{" "}
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-1afd2e9 elementor-widget elementor-widget-text-editor"
                          data-id="1afd2e9"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            {coreData?.data?.map((item, index) => {
                              return (
                                <p key={index}>
                                  <strong>{item?.title}: </strong>{item?.description}
                                </p>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>)}
                    {serviceData && (<div
                      className="elementor-element elementor-element-942ebb4 e-flex e-con-boxed e-con e-parent"
                      data-id="942ebb4"

                      data-element_type="container"
                      data-settings='{"background_background":"classic","content_width":"boxed"}'
                      data-core-v316-plus="true"
                    >
                      <div className="e-con-inner">
                        <div
                          className="elementor-element elementor-element-53990f7 e-flex e-con-boxed e-con e-child"
                          data-id="53990f7"
                          data-element_type="container"
                          data-settings='{"background_background":"classic","content_width":"boxed"}'
                        >
                          <div className="e-con-inner">
                            <div
                              className="elementor-element elementor-element-baab14f e-flex e-con-boxed e-con e-child"
                              data-id="baab14f"
                              style={{
                                backgroundImage: `url(${Constant?.IMAGE_URL_API}${serviceData?.img_url})`,
                              }}
                              data-element_type="container"
                              data-settings='{"background_background":"classic","content_width":"boxed"}'
                            >
                              <div className="e-con-inner">
                                <div
                                  className="elementor-element elementor-element-b0e92ab e-con-full e-flex e-con e-child"
                                  data-id="b0e92ab"
                                  data-element_type="container"
                                  data-settings='{"content_width":"full"}'
                                >
                                  <div
                                    className="elementor-element elementor-element-d32f89b elementor-widget elementor-widget-heading"
                                    data-id="d32f89b"
                                    data-element_type="widget"
                                    data-aos="fade-right"
                                    data-aos-easing="linear"
                                    data-aos-duration="1000"
                                    data-widget_type="heading.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <h2 className="elementor-heading-title elementor-size-default">
                                        {serviceData?.title}
                                      </h2>{" "}
                                    </div>
                                  </div>
                                  <div
                                    className="elementor-element elementor-element-f150e12 elementor-widget elementor-widget-heading"
                                    data-id="f150e12"
                                    data-element_type="widget"
                                    data-aos="fade-right"
                                    data-aos-easing="linear"
                                    data-aos-duration="1000"
                                    data-widget_type="heading.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <h2 className="elementor-heading-title elementor-size-default">
                                        {serviceData?.phoneno}
                                      </h2>{" "}
                                    </div>
                                  </div>
                                  <div
                                    className="elementor-element elementor-element-fb1401b elementor-widget elementor-widget-shortcode"
                                    data-id="fb1401b"
                                    data-element_type="widget"
                                    data-widget_type="shortcode.default"
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="elementor-shortcode">
                                        {" "}
                                        <div
                                          style={{ width: "auto" }}
                                          id="qlwapp"
                                          className="qlwapp qlwapp-js-ready qlwapp-button qlwapp-square"
                                        >
                                          <a
                                            className="qlwapp-toggle"
                                            href={serviceData?.whatsapp_link}
                                            target="_blank"
                                          >
                                            <i className="qlwapp-icon qlwapp-whatsapp-icon" />
                                            <i
                                              className="qlwapp-close"
                                              data-action="close"
                                            >
                                              ×
                                            </i>
                                            <span className="qlwapp-text">
                                              Whatsapp
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>)}
                  </div>
                </article>{" "}
                {/* end article */}
                <div className="clear" />
                {/* You can start editing here. */}
              </div>
              {/* end content */}
            </div>
            {/* end primary */}
          </div>
          {/* end container */}
        </div>
        {/* end wrapper */}
      </main>
    </div>
  )
}

export default Aboutus