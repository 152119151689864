import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Aboutus from './pages/aboutus';
import Home from './pages/home';
import ContactUs from './pages/contactus';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { Fragment, useEffect } from 'react';
import Products from './pages/Products/products';
import Gallery from './pages/gallery';
import Markets from './pages/markets';
import ScrollToTop from "react-scroll-to-top";
import Yarnpage from './pages/Products/cottonyarn';
import AOS from "aos";
import "aos/dist/aos.css";
import Notfound from './components/notfound';

function App() {

  const AppOutLet = () => {
    return (
      <>
        <div id="inner-body">
          <Header />
          <Outlet />
          <ScrollToTop smooth />
          <Footer />
        </div>
      </>
    )
  }

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route path="/" element={<AppOutLet />}>
              <Route path="/" element={<Home />}></Route>
              <Route path="/about-us" element={<Aboutus />}></Route>
              <Route path="/markets" element={<Markets />}></Route>
              <Route path="/products" element={<Products />}></Route>
              <Route path="/cotton-yarn" element={<Yarnpage />}></Route>
              <Route path="/gallery" element={<Gallery />}></Route>
              <Route path="/contact-us" element={<ContactUs />}></Route>
            </Route>
            <Route path="*" element={<Notfound />}></Route>
          </Routes>
        </Fragment>
      </BrowserRouter>
    </>
  );
}

export default App;

